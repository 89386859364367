import React from "react";
import ReactDOM from "react-dom/client";

import "./assets/css/materialdesignicons.min.css";
import "./assets/scss/themes.scss";

import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import App from "./App";
import PrivacyPolicy from "./pages/privacy-policy";
import TermConditions from "./pages/term-conditions";
import DeleteAccountInformation from "./pages/delete-account-information";
import "./i18n/i18n.config";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/term-conditions" element={<TermConditions />} />
      <Route
        path="/delete-account-information"
        element={<DeleteAccountInformation />}
      />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
