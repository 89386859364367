/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { Col, Container, Card, Row } from "reactstrap";
import { Link } from "react-router-dom";
import AppDevelopment from "../assets/images/bg/app-development.jpg";
import CTA from "../assets/images/bg/nadi-cta.jpg";
import { useTranslation } from "react-i18next";

export default function SectionSolution() {
  const { t, i18n } = useTranslation();
  const services = () => [
    {
      id: "service1",
      icon: "airplay",
      title: t("section_solution.services.s1.title"),
      description: t("section_solution.services.s1.desc"),
    },
    {
      id: "service2",
      icon: "circle-layer",
      title: t("section_solution.services.s2.title"),
      description: t("section_solution.services.s2.desc"),
    },
    {
      id: "service3",
      icon: "flip-h",
      title: t("section_solution.services.s3.title"),
      description: t("section_solution.services.s3.desc"),
    },
  ];

  const servicesData = useMemo(() => services(), [i18n.language]);

  return (
    <>
      <section className="section bg-light" id="solution">
        <Container>
          <Row className="justify-content-center">
            <div className="col-12">
              <div className="section-title text-center mb-4 pb-2">
                <h3 className="title mb-3">{t("section_solution.title")}</h3>
                <p className="text-muted mx-auto para-desc mb-0">
                  {t("section_solution.desc")}
                </p>
              </div>
            </div>
          </Row>

          <Row>
            {servicesData.map((item, key) => (
              <Col lg={4} md={6} className="mt-5 pt-4" key={key}>
                <Card className="features feature-primary feature-shadow-md shadow rounded p-4 pt-5">
                  <div className="icon-style mt-n80 position-relative text-center shadow rounded bg-white mb-4">
                    <i className={"h4 mb-0 uil uil-" + item.icon}></i>
                  </div>
                  <div className="content">
                    <Link to="#" className="title text-dark h5">
                      {item.title}
                    </Link>
                    <p className="text-muted mt-3">{item.description}</p>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          <div className="container mt-100 mt-60">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="me-lg-5">
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <div className="section-title text-center text-md-start mb-4 pb-2">
                        <h4 className="title mb-3">
                          {t("section_solution.optimize.title")}
                        </h4>
                        <p className="para-desc text-muted mx-auto mb-0">
                          {t("section_solution.optimize.desc")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <Row>
                    <div className="col-md-12 mt-4 pt-2">
                      <div className="features feature-primary rounded border-0 d-flex">
                        <div className="feature-icon sm-icon text-center">
                          <i className="rounded-pill">1</i>
                        </div>

                        <div className="content flex-1 ms-3">
                          <Link to="#" className="title h5 text-dark">
                            {t("section_solution.steps.s1.title")}
                          </Link>
                          <p className="text-muted mt-2 mb-0">
                            {t("section_solution.steps.s1.desc")}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-4 pt-2">
                      <div className="features feature-primary rounded border-0 d-flex">
                        <div className="feature-icon sm-icon text-center">
                          <i className="rounded-pill">2</i>
                        </div>

                        <div className="content flex-1 ms-3">
                          <Link to="#" className="title h5 text-dark">
                            {t("section_solution.steps.s2.title")}
                          </Link>
                          <p className="text-muted mt-2 mb-0">
                            {t("section_solution.steps.s2.desc")}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-4 pt-2">
                      <div className="features feature-primary rounded border-0 d-flex">
                        <div className="feature-icon sm-icon text-center">
                          <i className="rounded-pill">3</i>
                        </div>

                        <div className="content flex-1 ms-3">
                          <Link to="#" className="title h5 text-dark">
                            {t("section_solution.steps.s3.title")}
                          </Link>
                          <p className="text-muted mt-2 mb-0">
                            {t("section_solution.steps.s3.desc")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>

              <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="img-fluid-responsive position-relative">
                  <img
                    src={AppDevelopment}
                    className="mx-auto d-block"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="section" style={{ background: `url(${CTA}) center` }}>
        <div className="bg-overlay"></div>
        <Container>
          <div className="row justify-content-center">
            <div className="col">
              <div className="section-title text-center">
                <h4 className="title text-white mb-3">
                  {t("section_solution.ready_to_grow.title")}
                </h4>
                <p className="text-white-50 mx-auto para-desc mb-0">
                  {t("section_solution.ready_to_grow.desc")}
                </p>

                <div className="mt-4 pt-2">
                  <Link
                    to="https://play.google.com/store/apps/details?id=id.biz.nadi.kios&pcampaignid=web_share"
                    className="btn btn-primary"
                  >
                    {t("section_solution.ready_to_grow.button")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
